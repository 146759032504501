import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    baseURL_API:'https://socamad.systemsoca.com',//'http://localhost:8000',// 
    user: null,
    headers: {
      'headers': {
        'Authorization':  'Bearer ',
      }
    }
  },
  getters: {
  },
  mutations: {
    login(state,datos){
      state.user = datos.data;
      state.headers.headers.Authorization = 'Bearer ' + datos.access_token;
    },
    logout(state){
      state.user = null;
      state.headers.headers.Authorization = 'Bearer ';
    },
    comprobarPermiso(state,p){
      if (state.user.superuser+'' == '1') {
        return true;
      }

      if (!state.user.empleado || !state.user.empleado.permisos) {
        return false;
      }

      let ps = JSON.parse(state.user.empleado.permisos);

      for (let i = 0; i < ps.length; i++) {
        for (let j = 0; j < p.length; j++) {
          if (ps[i].slug == p[j]) {
            return true;
          }
        }
      }
      return false;
    },
  },
  actions: {
  },
  modules: {
  }
})
