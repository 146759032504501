import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import InicioView from '../views/InicioView.vue'
import SalaView from '../views/Negocio/SalaView.vue'
import CargoView from '../views/Negocio/CargoView.vue'
import EmpleadoView from '../views/Negocio/EmpleadoView.vue'
import CitaView from '../views/Preventa/CitaView.vue'
import MetaView from '../views/Preventa/MetaView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'inicio',
    component: InicioView
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },

  {
    path: '/salas',
    name: 'salas',
    component: SalaView
  },
  {
    path: '/cargos',
    name: 'cargos',
    component: CargoView
  },
  {
    path: '/empleados',
    name: 'empleados',
    component: EmpleadoView
  },
  {
    path: '/citas',
    name: 'citas',
    component: CitaView
  },
  {
    path: '/metas',
    name: 'metas',
    component: MetaView
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
