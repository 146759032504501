<template>
    <span>
        <b-btn @click="showModal"
            :variant="btnvariant? btnvariant : 'dark'"
            :size="btnsize? btnsize : 'sm'"
        >
            <b-icon v-if="btnicon" :icon="btnicon"></b-icon>
            {{ btntxt? btntxt : '' }}
        </b-btn>
        <b-modal ref="my-modal"
            header-bg-variant="dark"
            header-text-variant="white"
            ok-variant="dark"
            cancel-variant="danger"
            header-close-variant="dark"

            :size="mdlsize? mdlsize : 'md'"
            :hide-header="!mdltitle"
            hide-footer
            :title="mdltitle? mdltitle : ''"


            @ok="aceptar"
            
        >
            <center v-if="cargando">
                <b-spinner></b-spinner>
                <br>
                PROCESANDO...
            </center>
            <span v-else>
                <slot></slot>

                <span v-if="opciones">
                    <hr>
                    <b-btn variant="dark" @click="aceptar" class="m-1">
                        {{txtok? txtok: 'Aceptar'}}
                    </b-btn>
                    <b-btn variant="danger" @click="cancelar" class="m-1">
                        {{txtcancel? txtcancel: 'Cancelar'}}
                    </b-btn>
                </span>
            </span>
        </b-modal>
    </span>
</template>

<script>
export default {
    props: [
        'btnvariant',
        'btntxt',
        'btnicon',
        'btnsize',

        'mdlsize',
        'mdltitle',
        'opciones',

        'txtcancel',
        'txtok',

        'cargando',
    ],
    methods: {
        showModal(){
            this.$refs['my-modal'].show();
        },
        hideModal(){
            this.$refs['my-modal'].hide();
        },  
        cancelar(){
            this.$emit("cancelar");
            this.hideModal();
        },
        aceptar(){
            this.$emit("aceptar");
            this.hideModal();
        },
    },
}
</script>

<style>

</style>