<template>
    <div>
        <center>
            <b-nav>
                <b-nav-item v-for="(sala,isa) in salas" :key="isa" @click="setAPantalla(sala.nombre)">
                    {{sala.nombre}}
                </b-nav-item>
            </b-nav>
        </center>

        <div v-if="empleados.length > 0" class="table-responsive">
            <b-table striped :items="empleados" :fields="fields">
                <template #cell(metas_caritas)="data">
                    <span v-html="data.value"></span>
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    data(){
        return {
            salas:      [],
            empleados:  [],
            fields:     [
                { key: 'empleado_cod', label: 'Codigo', formatter: (value, key, item)=> {
                    return item.empleado.codigo;
                } },
                { key: 'empleado', label: 'Empleado', formatter: (value, key, item)=> {
                    return item.empleado.nombre + ' ' + item.empleado.apellido;
                } },
                { key: 'metas', label: 'C/M', formatter: (value, key, item)=> {
                    return item.conseguido + '/' + item.meta;
                } },
                { key: 'metas_caritas', label: 'Progreso', formatter: (value, key, item)=> {
                    let x = '';

                    for (let i = 0; i < item.conseguido; i++) {
                        x += '&#128516; ';
                    }
                    for (let i = 0; i < item.meta - item.conseguido; i++) {
                        x += '&#128533; ';
                    }

                    return x;
                } }
            ],
            sala:       '',
        }
    },
    methods: {
        async getSalas(){
            await axios.get(this.$store.state.baseURL_API + "/api/salas-all",this.$store.state.headers).then(r=>{
                this.salas = r.data;
            }).catch(e=>{
                console.log(e);
            });
        },  
        async getEmpleados(sala){
            this.sala = sala;
            await axios.get(this.$store.state.baseURL_API + "/api/empleados-sala/"+sala,this.$store.state.headers).then(r=>{
                this.empleados = r.data;

                this.ordenarEmpleados()
            }).catch(e=>{
                console.log(e);
            });
        },  
        ordenarEmpleados(){
            this.empleados.sort((a,b)=>{
                if (a.conseguido > b.conseguido) {
                    return 1;
                }
                if (a.conseguido < b.conseguido) {
                    return -1;
                }
                return 0;
            });
        },
        setAPantalla(sala){
            this.getEmpleados(sala);
            setTimeout(()=>{
                this.getEmpleados(sala);
            },10000)
        },
    },
    mounted(){
        this.getSalas();
    },
}
</script>
