<template>
    <div>

        <div class="container">
            <h1>
                <strong>
                    SALAS
                </strong>
                <b-btn variant="dark" @click="showModal">
                    <b-icon icon="plus"></b-icon>
                </b-btn>
            </h1>
            <hr>

            <my-table 
                :fields="fields"
                :url="$store.state.baseURL_API + '/api/salas'"
                :contador.sync="contador"
                :acciones="acciones"
                :accionesmasivas="accionesMasivas"
            ></my-table>
        </div>

        <b-modal ref="my-modal" :title="datos && datos.id? 'Editar sala':'Nueva sala'" hide-footer>
            <center v-if="cargando">
                <b-spinner></b-spinner>
                <br>
                PROCESANDO...
            </center>
            <form v-else-if="datos" @submit.prevent="guardar(datos.id?datos.id:0)" method="post">
                <b-form-group label="Nombre:" label-size="sm">
                    <b-form-input v-model="datos.nombre" required size="sm"></b-form-input>
                </b-form-group>
                <br>
                <b-btn type="submit" variant="dark">Guardar</b-btn>
            </form>
        </b-modal>
    </div>
</template>

<script>
import axios from 'axios';
import myTable from '../../components/my-table.vue';

export default {
    components: {
        myTable
    },
    data(){
        return {
            datos: null,
            salas: [],
            cargando: false,
            contador: 1,

            fields: [
                { key: 'action', label: 'Acciones' },
                { key: 'nombre', label: 'Sala', sortable: true },
            ],

            acciones: [
                {
                    variant:    'dark',
                    icon:       'pen',
                    callback:   this.showModal,
                    condicion:  (x)=>{return (x.id > 0 || true)}
                },
                {
                    variant:    'danger',
                    icon:       'trash',
                    callback:   this.delete,
                    condicion:  (x)=>{return (x.id > 0 || true)}
                },
            ],

            accionesMasivas: [
                {
                    variant:    'danger',
                    icon:       'trash',
                    txt:        'Eliminar seleccionados',
                    callback:   this.deleteMasivo,
                    condicion:  (x)=>{return (x.length > 0)}
                },
            ],
        }
    },  
    methods: {
        showModal(item=null){
            if (item) {
                this.datos = item;
            } else {
                this.datos = {
                    nombre: ''
                }
            }

            this.$refs['my-modal'].show();
        },
        hideModal(){
            this.$refs['my-modal'].hide();
        },
        async guardar(id=0){
            this.cargando = true;

            let form = {
                datos: JSON.stringify(this.datos),
            }

            await axios.post(this.$store.state.baseURL_API + "/api/sala" + (id>0 ? '/' + id : ''),form,this.$store.state.headers).then(r=>{
                console.log(r.data);
                
                this.hideModal();
                this.contador++;
                
                this.cargando = false;
            }).catch(e=>{
                console.log(e);               
                this.cargando = false;
            });
        },
        async delete(item){
            this.cargando = true;
            await axios.get(this.$store.state.baseURL_API + "/api/sala-delete/" + item.id,this.$store.state.headers).then(r=>{
                console.log(r.data);
                this.contador++;
                
                this.cargando = false;
            }).catch(e=>{
                console.log(e);               
                this.cargando = false;
            });
        },  
        async deleteMasivo(items){
            this.cargando = true;
            let form = {
                datos: []
            };
            for (let i = 0; i < items.length; i++) {
                form.datos.push(items[i].id);
            }
            await axios.post(this.$store.state.baseURL_API + "/api/sala-delete",form,this.$store.state.headers).then(r=>{
                console.log(r.data);
                this.contador++;
                
                this.cargando = false;
            }).catch(e=>{
                console.log(e);               
                this.cargando = false;
            });
        },  
    },  
}
</script>

<style>

</style>