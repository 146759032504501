<template>
  <div id="app">
    <b-navbar toggleable="lg" type="dark" variant="dark">
      <b-navbar-brand href="#">
        <b-button v-b-toggle.sidebar-1 class="mx-1" v-if="$store.state.user">
          <b-icon icon="grid"></b-icon>
        </b-button>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="mx-auto">

        </b-navbar-nav>

        <b-navbar-nav v-if="$store.state.user">
          <b-nav-item href="#">
            <router-link to="/home" class="nav-link">{{$store.state.user.email}}</router-link>
          </b-nav-item>
          <b-nav-item href="#">
            <a to="/about" class="nav-link" @click="logout">Cerrar sesión</a>
          </b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav v-else>
          <b-nav-item href="#">
            <router-link to="/login" class="nav-link">Iniciar sesión</router-link>
          </b-nav-item>
        </b-navbar-nav>
        
      </b-collapse>
    </b-navbar>

    <router-view/>

    <b-sidebar id="sidebar-1" title="SOC" shadow backdrop backdrop-variant="dark">
      

      <div class="accordion" role="tablist" v-if="$store.state.user">
        <b-card no-body class="mb-1" 
          v-if="comprobarPermiso(['salas.listar','cargos.listar','empleados.listar'])"
        >
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-btn block v-b-toggle.accordion-menu-lateral-1 variant="dark" class="w-100">Negocio</b-btn>
          </b-card-header>
          <b-collapse id="accordion-menu-lateral-1" accordion="my-accordion" role="tabpanel">
            <b-nav vertical>
              <b-nav-item v-if="comprobarPermiso(['salas.listar'])"><router-link to="/salas" class="nav-link">Salas</router-link></b-nav-item>
              <b-nav-item v-if="comprobarPermiso(['cargos.listar'])"><router-link to="/cargos" class="nav-link">Cargos</router-link></b-nav-item>
              <b-nav-item v-if="comprobarPermiso(['empleados.listar'])"><router-link to="/empleados" class="nav-link">Empleados</router-link></b-nav-item>
            </b-nav>
          </b-collapse>
        </b-card>
        <b-card no-body class="mb-1" v-if="comprobarPermiso(['metas.listar','citas.listar'])">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-btn block v-b-toggle.accordion-menu-lateral-2 variant="dark" class="w-100">Preventa</b-btn>
          </b-card-header>
          <b-collapse id="accordion-menu-lateral-2" accordion="my-accordion" role="tabpanel">
            <b-nav vertical>
              <b-nav-item v-if="comprobarPermiso(['citas.listar'])"><router-link to="/citas" class="nav-link">Citas</router-link></b-nav-item>
              <b-nav-item v-if="comprobarPermiso(['metas.listar'])"><router-link to="/metas" class="nav-link">Metas</router-link></b-nav-item>
            </b-nav>
          </b-collapse>
        </b-card>
      </div>
          
    </b-sidebar>

    <input type="hidden" :value="verificadorLogin">
  </div>
</template>

<script>
  import axios from 'axios';
  /* eslint-disable */
  export default {
    methods: {

      async logout(){
        await axios.get(this.$store.state.baseURL_API + "/api/logout",this.$store.state.headers).then(r=>{
          if (r.data.error) {
           console.log(r.data.error); 
          }else{
            this.$store.commit('logout');
          }
        }).catch(e=>{
          console.log(e);
          this.$store.commit('logout');
        });
      },

      async comprobarPermiso(p){
        return await this.$store.commit('comprobarPermiso',p);
      }
    },
    computed: {
      verificadorLogin: function () {
        let x = 0;
        if (!this.$store.state.user && this.$route.name != 'login') {
          this.$router.push('/login');
          x = 1;
        }

        return x;
      },
    },  

  }
</script>

<style>
</style>
