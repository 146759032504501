<template>
    <div>
        <div class="row">
            <b-form-group class="col-sm-4" label="Nombre:" label-size="sm">
                <b-form-input size="sm" v-model="datos.nombre" @change="store"></b-form-input>
            </b-form-group>
            <b-form-group class="col-sm-4" label="Apellido:" label-size="sm">
                <b-form-input size="sm" v-model="datos.apellido" @change="store"></b-form-input>
            </b-form-group>
            <b-form-group class="col-sm-4" label="Identificación:" label-size="sm">
                <b-form-input size="sm" v-model="datos.identificacion" @change="store"></b-form-input>
            </b-form-group>

            <b-form-group class="col-sm-6 col-md-3" label="Teléfono:" label-size="sm">
                <b-form-input size="sm" v-model="datos.telefono" @change="store"></b-form-input>
            </b-form-group>
            <b-form-group class="col-sm-6 col-md-3" label="Celular:" label-size="sm">
                <b-form-input size="sm" v-model="datos.celular" @change="store"></b-form-input>
            </b-form-group>
            <b-form-group class="col-sm-6 col-md-3" label="Email:" label-size="sm">
                <b-form-input size="sm" v-model="datos.email" type="email" @change="store"></b-form-input>
            </b-form-group>
            <b-form-group class="col-sm-6 col-md-3" label="Estado civil:" label-size="sm">
                <b-form-select size="sm" v-model="datos.estado_civil" :options="estados_civiles" @change="store"></b-form-select>
            </b-form-group>


            
            <b-form-group class="col-12" label="Dirección:" label-size="sm">
                <b-form-input size="sm" v-model="datos.direccion" @change="store"></b-form-input>
            </b-form-group>

        </div>
        <pais-ciudad v-if="datos.id"
            :preciudad="datos.ciudad"
            :preprovincia="datos.provincia"
            :prepais="datos.pais"

            @datoss="cambiaCiudad"
        ></pais-ciudad>

        <person-tarjetas v-if="datos.id" :prospectoid="datos.id"></person-tarjetas>
    </div>
</template>

<script>
import axios from 'axios'
import personTarjetas from './person-tarjetas.vue'
import paisCiudad from './pais-ciudad.vue'
export default {
    props: ['prospectoid'],
    components: {
        personTarjetas,
        paisCiudad
    },
    data(){
        return {
            datos: {
                nombre: '',
                apellido: '',
                identificacion: '',
                telefono: '',
                celular: '',
                email: '',
                estado_civil: '',
                direccion: '',
                ciudad: '',
                provincia: '',
                pais: '',
            },

            estados_civiles: [
                'SOLTERO',
                'CASADO',
                'DIVORCIADO',
                'VIUDO',
                'UNION LIBRE',
            ],
        }
    },  
    methods: {
        cambiaCiudad(e){
            console.log(e);
            this.datos.ciudad = e.ciudad;
            this.datos.provincia = e.provincia;
            this.datos.pais = e.pais;
            this.store();
        },
        async getOne(){
            await axios.get(this.$store.state.baseURL_API + '/api/prospecto/' + this.prospectoid ,this.$store.state.headers).then(r=>{
                this.datos = r.data;
            }).catch(e=>{
                console.log(e);
            });
        },
        async store(){
            let form = {
                datos: JSON.stringify(this.datos)
            }

            axios.post(this.$store.state.baseURL_API + '/api/prospecto-automatico' + (this.datos && this.datos.id? '/' + this.datos.id : '') , form ,this.$store.state.headers).then(r=>{
                this.datos = r.data;
                this.$emit('prospecto',this.datos.id);
            }).catch(e=>{
                console.log(e);
            });
        },
    },
    mounted(){
        if (this.prospectoid) {
            this.getOne();
        }
    },
}
</script>

<style>

</style>