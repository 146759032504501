<template>
    <div>
        <div class="row">
            <b-form-group label="Pais:" aria-setsize="sm" class="col-sm-4">
                <b-form-select v-model="pais" :options="paises" @change="setProvincias"></b-form-select>
            </b-form-group>
            <b-form-group label="Provincia:" aria-setsize="sm" class="col-sm-4">
                <b-form-select v-model="provincia" :options="provincias" @change="setCiudades"></b-form-select>
            </b-form-group>
            <b-form-group label="Ciudades:" aria-setsize="sm" class="col-sm-4">
                <b-form-select v-model="ciudad" :options="ciudades" @change="datoss"></b-form-select>
            </b-form-group>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    props: ['preciudad','preprovincia','prepais'],
    data(){
        return {
            ciudad: '',
            provincia: '',
            pais: '',

            paises: [],
            provincias: [],
            ciudades: [],
        }
    },
    methods: {
        setProvincias(){
            this.ciudades = [];
            this.provincias = [];
            for (let i = 0; i < this.paises.length; i++) {
                if (this.pais == this.paises[i].pais.pais) {
                    this.provincias = this.paises[i].pais.provincias.map(provincia=>{
                        return {
                            text: provincia.provincia,
                            value: provincia.provincia,
                            provincia: provincia,
                        }
                    })
                }
            }
        },
        datoss(){
            console.log("emitiendo");
            this.$emit('datoss',{
                pais: this.pais,
                provincia: this.provincia,
                ciudad: this.ciudad,
            })
        },  
        setCiudades(){
            this.ciudades = [];
            for (let i = 0; i < this.provincias.length; i++) {
                if (this.provincia == this.provincias[i].provincia.provincia) {
                    this.ciudades = this.provincias[i].provincia.ciudades.map(ciudad=>{
                        return {
                            text: ciudad,
                            value: ciudad,
                        }
                    })
                }
            }
        },
        async getCiudades(){
            await axios.get(this.$store.state.baseURL_API + '/api/ciudades' ,this.$store.state.headers).then(r=>{
                this.paises = r.data.map(pais=>{
                    return {
                        text: pais.pais,
                        pais: pais,
                        value: pais.pais
                    }
                });

                if (this.prepais) {
                    this.pais = this.prepais;
                    this.setProvincias();

                    if (this.preprovincia) {
                        this.provincia = this.preprovincia;
                        this.setCiudades();

                        if (this.preciudad) {
                            this.ciudad = this.preciudad;
                        }
                    }
                }
            }).catch(e=>{
                console.log(e);
            })
        }
    },
    mounted(){
        this.getCiudades();
    },
}
</script>

<style>

</style>