<template>
    <div>

        <div class="container">
            <h1>
                <strong>
                    METAS
                </strong>
                <b-btn variant="dark" @click="showModal">
                    <b-icon icon="plus"></b-icon>
                </b-btn>
            </h1>
            <hr>

            <my-table 
                :fields="fields"
                :url="$store.state.baseURL_API + '/api/metas'"
                :contador.sync="contador"
                :acciones="acciones"
                :accionesmasivas="accionesMasivas"
            ></my-table>
        </div>

        <b-modal ref="my-modal" :title="datos && datos.id? 'Editar meta':'Nueva meta'" hide-footer>
            <center v-if="cargando">
                <b-spinner></b-spinner>
                <br>
                PROCESANDO...
            </center>
            <form class="row" v-else-if="datos" @submit.prevent="guardar(datos.id?datos.id:0)" method="post">
                <b-form-group class="col-12 col-sm-4" label="Empleado:" label-size="sm">
                    <b-form-select :options="empleados" v-model="datos.empleado_id" required size="sm"></b-form-select>
                </b-form-group>
                <b-form-group class="col-12 col-sm-4" label="Meta:" label-size="sm">
                    <b-form-input type="number" step="1" min="1" max="1000" v-model="datos.meta" required size="sm"></b-form-input>
                </b-form-group>
                <b-form-group class="col-12 col-sm-4" label="Fecha:" label-size="sm">
                    <b-form-input type="date" v-model="datos.fecha" size="sm"></b-form-input>
                </b-form-group>
                <div class="mt-1">
                    <b-btn type="submit" variant="dark">Guardar</b-btn>
                </div>
            </form>
        </b-modal>
    </div>
</template>

<script>
import axios from 'axios';
import myTable from '../../components/my-table.vue';

export default {
    components: {
        myTable
    },
    data(){
        return {
            datos: null,
            metas: [],
            cargando: false,
            contador: 1,

            fields: [
                { key: 'action', label: 'Acciones' },
                { key: 'sala', label: 'Meta', sortable: true },
                { key: 'fecha', label: 'Fecha', sortable: true },
                { key: 'meta', label: 'Meta', sortable: true },
                { key: 'conseguido', label: 'Alcanzado', sortable: true },
                { key: 'empleado_id', label: 'Empleado', sortable: true, formatter: (value, key, item)=>{
                    return item.empleado.codigo + ' - ' + item.empleado.nombre + ' ' + item.empleado.apellido
                } },
            ],

            acciones: [
                {
                    variant:    'dark',
                    icon:       'pen',
                    callback:   this.showModal,
                    condicion:  (x)=>{return (x.id > 0 || true)}
                },
                {
                    variant:    'danger',
                    icon:       'trash',
                    callback:   this.delete,
                    condicion:  (x)=>{return (x.id > 0 || true)}
                },
            ],

            accionesMasivas: [
                {
                    variant:    'danger',
                    icon:       'trash',
                    txt:        'Eliminar seleccionados',
                    callback:   this.deleteMasivo,
                    condicion:  (x)=>{return (x.length > 0)}
                },
            ],

            empleados: [],
        }
    },  
    methods: {
        showModal(item=null){
            if (item) {
                this.datos = item;
            } else {
                this.datos = {
                    empleado_id:'',
                    meta:       0,
                    sala:       '',
                    conseguido: 0,
                    fecha:      '',
                }
            }

            this.$refs['my-modal'].show();
        },
        hideModal(){
            this.$refs['my-modal'].hide();
        },
        async guardar(id=0){
            this.cargando = true;

            let form = {
                datos: JSON.stringify(this.datos),
            }

            await axios.post(this.$store.state.baseURL_API + "/api/meta" + (id>0 ? '/' + id : ''),form,this.$store.state.headers).then(r=>{
                console.log(r.data);
                
                this.hideModal();
                this.contador++;
                
                this.cargando = false;
            }).catch(e=>{
                console.log(e);               
                this.cargando = false;
            });
        },
        async delete(item){
            this.cargando = true;
            await axios.get(this.$store.state.baseURL_API + "/api/meta-delete/" + item.id,this.$store.state.headers).then(r=>{
                console.log(r.data);
                this.contador++;
                
                this.cargando = false;
            }).catch(e=>{
                console.log(e);               
                this.cargando = false;
            });
        },  
        async deleteMasivo(items){
            this.cargando = true;
            let form = {
                datos: []
            };
            for (let i = 0; i < items.length; i++) {
                form.datos.push(items[i].id);
            }
            await axios.post(this.$store.state.baseURL_API + "/api/meta-delete",form,this.$store.state.headers).then(r=>{
                console.log(r.data);
                this.contador++;
                
                this.cargando = false;
            }).catch(e=>{
                console.log(e);               
                this.cargando = false;
            });
        },
        async getEmpleados(){
            await axios.get(this.$store.state.baseURL_API + "/api/empleados-all",this.$store.state.headers).then(r=>{
                this.empleados = r.data.map((empleado)=>{
                    return {
                        text:   empleado.codigo + ' - ' + empleado.nombre + ' ' + empleado.apellido,
                        value:  empleado.id
                    }
                });
            }).catch(e=>{
                console.log(e);
            });
        },
    },  
    mounted(){
        this.getEmpleados();
    },
}
</script>

<style>

</style>