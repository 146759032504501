<template>
    <div>

        <div class="container">
            <h1>
                <strong>
                    EMPLEADOS
                </strong>
                <b-btn variant="dark" @click="showModal">
                    <b-icon icon="plus"></b-icon>
                </b-btn>
            </h1>
            <hr>

            <my-table 
                :fields="fields"
                :url="$store.state.baseURL_API + '/api/empleados'"
                :contador.sync="contador"
                :acciones="acciones"
                :accionesmasivas="accionesMasivas"
            ></my-table>
        </div>

        <b-modal ref="my-modal-2" title="Usuario relacionado" hide-footer>
            <center v-if="cargando">
                <b-spinner></b-spinner>
                <br>
                PROCESANDO...
            </center>
            <form class="row" v-else-if="usuario" @submit.prevent="guardarUser(usuario.empleado_id)" method="post">
                <strong>
                    Empleado "{{ usuario.name }}"
                </strong>
                <b-form-group label="Email:" label-size="sm">
                    <b-form-input v-model="usuario.email" required type="email" size="sm"></b-form-input>
                </b-form-group>
                <b-form-group label="Clave:" label-size="sm">
                    <b-form-input v-model="usuario.password" :required="!usuario.id || usuario.id <= 0" type="password" size="sm"></b-form-input>
                </b-form-group>
                <hr>
                <b-btn type="submit" variant="dark">
                    Guardar
                </b-btn>
            </form>
        </b-modal>

        <b-modal ref="my-modal" :title="datos && datos.id? 'Editar empleado':'Nueva empleado'" hide-footer>
            <center v-if="cargando">
                <b-spinner></b-spinner>
                <br>
                PROCESANDO...
            </center>
            <form class="row" v-else-if="datos" @submit.prevent="guardar(datos.id?datos.id:0)" method="post">
                <b-form-group class="col-sm-4" label="Nombre:" label-size="sm">
                    <b-form-input v-model="datos.nombre" required size="sm"></b-form-input>
                </b-form-group>
                <b-form-group class="col-sm-4" label="Apellido:" label-size="sm">
                    <b-form-input v-model="datos.apellido" required size="sm"></b-form-input>
                </b-form-group>
                <b-form-group class="col-sm-4" label="Identificación:" label-size="sm">
                    <b-form-input v-model="datos.identificacion" required size="sm"></b-form-input>
                </b-form-group>

                
                <b-form-group class="col-sm-6" label="Sala:" label-size="sm">
                    <b-form-select v-model="datos.sala" required size="sm" :options="salas"></b-form-select>
                </b-form-group>
                <b-form-group class="col-sm-6" label="Cargo:" label-size="sm">
                    <b-form-select v-model="datos.cargo_id" required size="sm" :options="cargos"></b-form-select>
                </b-form-group>
                
                <div class="mt-1">
                    <b-btn type="submit" variant="dark">Guardar</b-btn>
                </div>
            </form>
        </b-modal>
    </div>
</template>

<script>
import axios from 'axios';
import myTable from '../../components/my-table.vue';

export default {
    components: {
        myTable
    },
    data(){
        return {
            datos: null,
            usuario: null,
            empleados: [],
            cargando: false,
            contador: 1,

            fields: [
                { key: 'action', label: 'Acciones' },
                { key: 'codigo', label: 'Código', sortable: true },
                { key: 'identificacion', label: 'Identificación', sortable: true },
                { key: 'nombre', label: 'Nombre', sortable: true },
                { key: 'apellido', label: 'Apellido', sortable: true },
                { key: 'sala', label: 'Sala', sortable: true },
                { key: 'cargo_id', label: 'Cargo', sortable: true, formatter: (value, key, item)=>{
                    if (!item.cargo) {
                        return 'Ninguno';
                    }
                    return item.cargo.nombre;
                } },
                { key: 'user_id', label: 'usuario', sortable: true, formatter: (value, key, item)=>{
                    if (!item.user) {
                        return 'Ninguno';
                    }
                    return item.user.email;
                } },
            ],

            acciones: [
                {
                    variant:    'dark',
                    icon:       'pen',
                    callback:   this.showModal,
                    condicion:  (x)=>{return (x.id > 0 || true)}
                },
                {
                    variant:    'secondary',
                    icon:       'user',
                    callback:   this.showModal2,
                    condicion:  (x)=>{return (x.id > 0 || true)}
                },
                {
                    variant:    'danger',
                    icon:       'trash',
                    callback:   this.delete,
                    condicion:  (x)=>{return (x.id > 0 || true)}
                },
            ],

            accionesMasivas: [
                {
                    variant:    'danger',
                    icon:       'trash',
                    txt:        'Eliminar seleccionados',
                    callback:   this.deleteMasivo,
                    condicion:  (x)=>{return (x.length > 0)}
                },
            ],

            salas:  [],
            cargos: [],
        }
    },  
    methods: {
        showModal(item=null){
            if (item) {
                this.datos = item;
            } else {
                this.datos = {
                    nombre:         '',
                    apellido:       '',
                    identificacion: '',
                    sala:           '',
                    cargo_id:       '',
                    user_id:        '',
                }
            }

            this.$refs['my-modal'].show();
        },
        showModal2(item){
            this.usuario = {
                name:       item.nombre + ' ' + item.apellido,
                email:      '',
                password:   '',
                empleado_id:item.id,
            }

            if (item.user) {
                this.usuario.id     = item.user.id;
                this.usuario.email  = item.user.email;
            }

            this.$refs['my-modal-2'].show();
        },
        hideModal(){
            this.$refs['my-modal'].hide();
        },
        hideModal2(){
            this.$refs['my-modal-2'].hide();
        },
        async guardarUser(id){
            this.cargando = true;

            let form = {
                datos: JSON.stringify(this.usuario),
            }

            await axios.post(this.$store.state.baseURL_API + "/api/empleado-user/" + id,form,this.$store.state.headers).then(r=>{
                console.log(r.data);
                
                this.hideModal2();
                this.contador++;
                
                this.cargando = false;
            }).catch(e=>{
                console.log(e);               
                this.cargando = false;
            });
        },
        async guardar(id=0){
            this.cargando = true;

            let form = {
                datos: JSON.stringify(this.datos),
            }

            await axios.post(this.$store.state.baseURL_API + "/api/empleado" + (id>0 ? '/' + id : ''),form,this.$store.state.headers).then(r=>{
                console.log(r.data);
                
                this.hideModal();
                this.contador++;
                
                this.cargando = false;
            }).catch(e=>{
                console.log(e);               
                this.cargando = false;
            });
        },
        async delete(item){
            this.cargando = true;
            await axios.get(this.$store.state.baseURL_API + "/api/empleado-delete/" + item.id,this.$store.state.headers).then(r=>{
                console.log(r.data);
                this.contador++;
                
                this.cargando = false;
            }).catch(e=>{
                console.log(e);               
                this.cargando = false;
            });
        },  
        async deleteMasivo(items){
            this.cargando = true;
            let form = {
                datos: []
            };
            for (let i = 0; i < items.length; i++) {
                form.datos.push(items[i].id);
            }
            await axios.post(this.$store.state.baseURL_API + "/api/empleado-delete",form,this.$store.state.headers).then(r=>{
                console.log(r.data);
                this.contador++;
                
                this.cargando = false;
            }).catch(e=>{
                console.log(e);               
                this.cargando = false;
            });
        },
        async getSalas(){
            await axios.get(this.$store.state.baseURL_API + "/api/salas-all",this.$store.state.headers).then(r=>{
                this.salas = r.data.map(function(sala){
                    return sala.nombre;
                });
            }).catch(e=>{
                console.log(e);
            });
        },
        async getCargos(){
            await axios.get(this.$store.state.baseURL_API + "/api/cargos-all",this.$store.state.headers).then(r=>{
                this.cargos = r.data.map(function(cargo){
                    return {
                        text:   cargo.nombre,
                        value:  cargo.id,
                    };
                });
            }).catch(e=>{
                console.log(e);
            });
        },
    },  
    mounted(){
        this.getSalas();
        this.getCargos();
    },
}
</script>

<style>

</style>