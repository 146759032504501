<template>
    <div>

        <div class="container">
            <h1>
                <strong>
                    CARGOS
                </strong>
                <b-btn variant="dark" @click="showModal(null)">
                    <b-icon icon="plus"></b-icon>
                </b-btn>
            </h1>
            <hr>

            <my-table 
                :fields="fields"
                :url="$store.state.baseURL_API + '/api/cargos'"
                :contador.sync="contador"
                :acciones="acciones"
                :accionesmasivas="accionesMasivas"
            ></my-table>
        </div>

        <b-modal ref="my-modal" :title="datos && datos.id? 'Editar cargo':'Nuevo cargo'" hide-footer>
            <center v-if="cargando">
                <b-spinner></b-spinner>
                <br>
                PROCESANDO...
            </center>
            <form class="row" v-else-if="datos" @submit.prevent="guardar(datos.id?datos.id:0)" method="post">
                <b-form-group class="col-sm-4" label="Nombre:" label-size="sm">
                    <b-form-input v-model="datos.nombre" required size="sm"></b-form-input>
                </b-form-group>
                <b-form-group class="col-sm-4" label="Iniciales:" label-size="sm">
                    <b-form-input v-model="datos.inicial" required size="sm"></b-form-input>
                </b-form-group>
                <b-form-group class="col-sm-4" label="Secuencial:" label-size="sm">
                    <b-form-input v-model="datos.secuencial" required size="sm"></b-form-input>
                </b-form-group>
                <div class="col-12 mt-1">
                    <b-form-checkbox-group
                        v-model="datos.permisos"
                        :options="permisos"
                    >
                    </b-form-checkbox-group>
                </div>
                <div class="mt-1">
                    <b-btn type="submit" variant="dark">Guardar</b-btn>
                </div>
            </form>
        </b-modal>
    </div>
</template>

<script>
import axios from 'axios';
import myTable from '../../components/my-table.vue';

export default {
    components: {
        myTable
    },
    data(){
        return {
            datos: null,
            cargos: [],
            cargando: false,
            contador: 1,

            fields: [
                { key: 'action', label: 'Acciones' },
                { key: 'nombre', label: 'Cargo', sortable: true },
                { key: 'inicial', label: 'Inicial', sortable: true },
                { key: 'secuencial', label: 'Secuencial', sortable: true },
            ],

            acciones: [
                {
                    variant:    'dark',
                    icon:       'pen',
                    callback:   this.showModal,
                    condicion:  (x)=>{return (x.id > 0 || true)}
                },
                {
                    variant:    'danger',
                    icon:       'trash',
                    callback:   this.delete,
                    condicion:  (x)=>{return (x.id > 0 || true)}
                },
            ],

            accionesMasivas: [
                {
                    variant:    'danger',
                    icon:       'trash',
                    txt:        'Eliminar seleccionados',
                    callback:   this.deleteMasivo,
                    condicion:  (x)=>{return (x.length > 0)}
                },
            ],

            permisos: [],
        }
    },  
    methods: {
        showModal(item=null){
            if (item) {
                this.datos = Object.assign(item,{permisos: (typeof(item.permisos) === 'string' ? JSON.parse(item.permisos):item.permisos)});
            } else {
                this.datos = {
                    nombre:     '',
                    inicial:    '',
                    secuencial: '',
                    permisos: [],
                }
            }

            this.$refs['my-modal'].show();
        },
        hideModal(){
            this.$refs['my-modal'].hide();
        },
        async guardar(id=0){
            this.cargando = true;

            let obj = Object.assign(this.datos,{permisos:JSON.stringify(this.datos.permisos)});

            let form = {
                datos: JSON.stringify(obj),
            }

            await axios.post(this.$store.state.baseURL_API + "/api/cargo" + (id>0 ? '/' + id : ''),form,this.$store.state.headers).then(r=>{
                console.log(r.data);
                
                this.hideModal();
                this.contador++;
                
                this.cargando = false;
            }).catch(e=>{
                console.log(e);               
                this.cargando = false;
            });
        },
        async delete(item){
            this.cargando = true;
            await axios.get(this.$store.state.baseURL_API + "/api/cargo-delete/" + item.id,this.$store.state.headers).then(r=>{
                console.log(r.data);
                this.contador++;
                
                this.cargando = false;
            }).catch(e=>{
                console.log(e);               
                this.cargando = false;
            });
        },  
        async deleteMasivo(items){
            this.cargando = true;
            let form = {
                datos: []
            };
            for (let i = 0; i < items.length; i++) {
                form.datos.push(items[i].id);
            }
            await axios.post(this.$store.state.baseURL_API + "/api/cargo-delete",form,this.$store.state.headers).then(r=>{
                console.log(r.data);
                this.contador++;
                
                this.cargando = false;
            }).catch(e=>{
                console.log(e);               
                this.cargando = false;
            });
        },  
        async getPermisos(){
            this.cargando = true;
            
            this.permisos = [];
            await axios.get(this.$store.state.baseURL_API + "/api/permisos",this.$store.state.headers).then(r=>{
                this.contador++;

                this.permisos = r.data.map(function(permiso){
                    return {
                        text: permiso.nombre,
                        value: permiso,
                    }
                });
                
                this.cargando = false;
            }).catch(e=>{
                console.log(e);               
                this.cargando = false;
            });
        },
    },  
    mounted(){
        this.getPermisos();
    },  
}
</script>

<style>

</style>