<template>
    <div>
        <my-modal 
            btnvariant  = "dark"
            btntxt      = "Tarjetas"
            btnicon     = "card"
            opciones    = "true"
            txtok       = "Guardar cambios"

            @aceptar    = "store"
        >
            <div class="row">
                <b-form-group class="col-sm-5" label="Tarjeta:" label-size="sm">
                    <b-form-select size="sm" v-model="tarjeta" :options="pre_tarjetas" @change="()=>{tipos=tarjeta.tipos;tarjeta.tipo='';}"></b-form-select>
                </b-form-group>
                <b-form-group class="col-sm-5" label="Tipo:" v-if="tipos.length > 0" label-size="sm">
                    <b-form-select size="sm" v-model="tarjeta.tipo" :options="tipos"></b-form-select>
                </b-form-group>
                <b-btn variant="success" @click="addT"> 
                    <b-icon icon="plus"></b-icon>
                </b-btn>
            </div>

            <hr>

            <div class="m-1 border border-dark" v-for="(t,i) in tarjetas" :key="i">
                <b-btn variant="danger" @click="delT(i)">
                    <b-icon icon="trash"></b-icon>
                </b-btn> 
                {{ t.siglas }} - {{ t.tarjeta }} - {{ t.tipo }}
            </div>
        </my-modal>
    </div>
</template>

<script>
import axios from 'axios'
import myModal from './my-modal.vue'
export default {
    props: ['prospectoid'],
    components: {
        myModal
    },
    data(){
        return {
            tarjetas: [],
            identificacion: '',
            pre_tarjetas: [],
            tipos: [],
            tarjeta: null
        }
    },
    methods: {
        addT(){
            this.tarjetas.push(this.tarjeta);
            this.tarjeta = null;
            this.tipos = [];
        },
        delT(i){
            this.tarjetas.splice(i,1);
        },  
        async getOne(){
            await axios.get(this.$store.state.baseURL_API + '/api/prospecto/' + this.prospectoid ,this.$store.state.headers).then(r=>{
                
                this.tarjetas = [];

                this.identificacion = r.data.identificacion;

                if (r.data.tarjetas) {
                    this.tarjetas = JSON.parse(r.data.tarjetas);
                }
            }).catch(e=>{
                console.log(e);
            });
        },
        async getTarjetas(){
            await axios.get(this.$store.state.baseURL_API + '/api/tarjetas',this.$store.state.headers).then(r=>{
                
                this.pre_tarjetas = [];

                console.log(r.data);
                if (r.data) {
                    this.pre_tarjetas = r.data.map(t=>{
                        return {
                            value: t,
                            text: t.siglas + ' - ' + t.tarjeta 
                        }
                    });
                }
            }).catch(e=>{
                console.log(e);
            });
        },
        async store(){
            let tarjetas = JSON.stringify(this.tarjetas);
            let form = {
                datos: {
                    tarjetas: tarjetas,
                    identificacion: this.identificacion
                }
            }

            axios.post(this.$store.state.baseURL_API + '/api/prospecto-automatico/' + this.prospectoid , form ,this.$store.state.headers).then(r=>{
                console.log(r.data);
            }).catch(e=>{
                console.log(e);
            });
        },
    },
    mounted(){
        this.getOne();
        this.getTarjetas();
    },
}
</script>

<style>

</style>