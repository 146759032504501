<template>
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6 mx-auto mt-4">
                <div v-if="cargando">
                    <center>
                        <b-spinner></b-spinner>
                        <br>
                        CARGANDO ...
                    </center>
                </div>
                <b-card title="Iniciar sesión" bg-variant="dark" text-variant="white" v-else>
                    <b-card-body>
                        <b-alert variant="danger" v-if="error" show>
                            {{error}}
                        </b-alert>
                        <b-form @submit.prevent="login">
                            <b-form-group label="Usuario:" label-size="sm" class="mt-1">
                                <b-form-input v-model="email" required type="email" size="sm" placeholder="test@test.com"></b-form-input>
                            </b-form-group>
                            <b-form-group label="Clave:" label-size="sm" class="mt-1">
                                <b-form-input v-model="clave" required type="password" size="sm" placeholder="********"></b-form-input>
                            </b-form-group>
                            <hr>
                            <b-btn type="submit" variant="primary">
                                Iniciar sesión
                            </b-btn>
                        </b-form>
                    </b-card-body>
                </b-card>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data(){
        return {
            email:    '',
            clave:    '',
            error:    '',

            cargando: false,
        }
    },
    methods: {
        async login(){
            this.cargando = true;

            let form = {
                email: this.email,
                password: this.clave
            };

            await axios.post(this.$store.state.baseURL_API + "/api/login",form).then(r=>{
                if (r.data.data) {
                    this.$store.commit('login',r.data);
                    this.error = '';
                    this.$router.push("home");
                }else{
                    this.$store.commit('logout');
                    this.error = r.data.error;
                }
                this.cargando = false;
            }).catch(e=>{
                if (e.response.data.error) {
                    this.error = e.response.data.error;
                } else {
                    this.error = e.message;
                }
                this.$store.commit('logout');
                this.cargando = false;
            });
        },

    },
    
}
</script>

<style>

</style>