<template>
    <div>
        <div class="container">
            <div class="row">
                <div class="col-12 col-sm-6">
                    <b-pagination size="sm" :total-rows="totalRows" v-model="current_page" :per-page="per_page" @input="actualizarTabla"></b-pagination>
                </div>
                <div class="col-12 col-sm-6">
                    <b-form-select size="sm" :options="listaPaginas" v-model="per_page" @change="actualizarTabla"></b-form-select>
                </div>
            </div>
        </div>


        <b-table 
            ref="my-table"
            striped 
            selectable
            hover  
            foot-clone
            responsive 
            :fields="fields" 
            :items="getDatos"
            :busy.sync="isBusy"

            :no-provider-paging="true"

            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            
            label-sort-asc=""
            label-sort-clear=""
            label-sort-desc=""

            @row-selected="seleccionarFilas"
        >
            <template #table-busy>
                <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Cargando...</strong>
                </div>
            </template>

            <template #cell(action)="data">
                <span v-if="acciones">
                    <span v-for="(accion,iaccion) in acciones" :key="iaccion" >
                        <my-modal :btnvariant="accion.variant"
                            :btnicon="accion.icon? accion.icon : ''"
                            :btntxt="accion.txt? accion.txt : ''"
                            btnsize="sm"

                            :opciones="true"

                            @aceptar="accion.callback(data.item)"
                            v-if="accion.condicion(data.item)"
                        >
                            ¿Esta seguro de esta acción??
                        </my-modal>
                    </span>
                </span>
            </template>
        </b-table>
        
        <div class="container">
            <div class="row">
                <div class="col-12 col-sm-6">
                    <b-pagination size="sm" :total-rows="totalRows" v-model="current_page" :per-page="per_page" @input="actualizarTabla"></b-pagination>
                </div>
                <div class="col-12 col-sm-6">
                    <b-form-select size="sm" :options="listaPaginas" v-model="per_page" @change="actualizarTabla"></b-form-select>
                </div>

                <div class="col-12">
                    <center>
                        <b-btn size="sm" @click="seleccionarTodo" class="m-1">Seleccionar todo</b-btn>
                        <b-btn size="sm" v-if="seleccionados.length > 0" @click="seleccionarNada" class="m-1">Deseleccionar</b-btn>

                        <span v-if="accionesmasivas">
                            <span v-for="(accion,iaccion) in accionesmasivas" :key="iaccion" >
                                <my-modal :btnvariant="accion.variant"
                                    :btnicon="accion.icon? accion.icon : ''"
                                    :btntxt="accion.txt? accion.txt : ''"
                                    btnsize="sm"

                                    :opciones="true"

                                    @aceptar="accion.callback(seleccionados)"
                                    v-if="accion.condicion(seleccionados)"
                                >
                                    ¿Esta seguro de esta acción??
                                </my-modal>
                            </span>
                        </span>
                    </center>
                </div>
            </div>
        </div>

        <input type="hidden" :value="observador_r">
    </div>
</template>

<script>
/* eslint-disable */
import axios from 'axios';
import myModal from './my-modal.vue';

export default {
    props: [
        'fields',
        'url',
        'contador',
        'acciones',
        'accionesmasivas',
    ],
    components: {
        myModal
    },
    data(){
        return {
            conteoActual: 1,

            isBusy: false,
            sortBy: '',
            sortDesc: false,
            totalRows: 0,
            current_page: 1,
            per_page: 5,
            listaPaginas: [
                5,
                10,
                20,
                50,
                100,
                200,
                500
            ],

            seleccionados: [],
        }
    },
    methods: {
        seleccionarFilas(items){
            this.seleccionados = items;
        },
        seleccionarTodo(){
            this.$refs['my-table'].selectAllRows();
        },
        seleccionarNada(){
            this.$refs['my-table'].clearSelected();
        },
        async getDatos(ctx){
            this.isBusy = true;
            try {
                console.log(ctx);
                const response = await axios.get(this.url 
                                + '/?page=' 
                                + this.current_page 
                                + '&perPage=' 
                                + this.per_page
                                + '&orderBy=' 
                                + this.sortBy
                                + '&orderDesc=' 
                                + (this.sortDesc? 'desc':'asc') 
                                , this.$store.state.headers);
                this.isBusy = false;
                this.totalRows = response.data.total;
                return response.data.data;
            } catch (err) {
                this.isBusy = false;
                return [];
            }
        },
        actualizarTabla(){
            this.$refs['my-table'].refresh();
        },  
    },
    computed: {
        observador_r: function(){
            if (this.contador && this.contador != this.conteoActual) {
                this.conteoActual = this.contador; 
                this.actualizarTabla();
            }

            return this.conteoActual;
        },
    },  
}
</script>

<style>

</style>